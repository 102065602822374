<template>
  <div class="content" style="margin-left: 57px; margin-bottom: 70px;">
    <div class="content-header">
      <p class="content-header-text">
        Finansal <span style="font-weight: 600">İşlemler</span>
      </p>
      <p style="font-size: 18px; color: #768191; font-weight: normal; margin-top: 10px">
        Bionluk üzerinden yaptığın alış ve satış işlemlerine ait kayıtlara buradan ulaşabilirsin.
      </p>
    </div>

    <div v-if="showInfo" class="finance-info" :class="!user.seller.isSeller ? 'for-seller': null">
      <div @click="hideInfo" style="position: absolute; top: 15px; right: 15px; cursor: pointer">
        <img src="https://gcdn.bionluk.com/site/cicons/ic-delete.svg" onload="SVGInject(this)" class="delete-icon"/>
      </div>
      <div v-show="!user.seller.isSeller" style="margin-left: 20px">
        <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="for-seller-icon"/>
      </div>
      <div v-show="user.seller.isSeller" style="margin-left: 20px">
        <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="for-buyer-icon"/>
      </div>
      <div style="margin-left: 15px">
        <div class="title">
          {{ infoTextTitle }}
        </div>
        <div class="description">
          <span v-if="!user.seller.isSeller ">Hayır ödemen güvenli bir şekilde Bionluk havuz hesabında bekletilir. Siparişe onay verdiğinde, her şey yolundaysa ödeme 2-5 iş günü içinde freelancer’a aktarılır.</span>
          <span v-else>Onaylanan siparişlerin ödemesi kontrol edildikten sonra, her şey yolundaysa <span style="font-weight: bold">2-5</span> iş günü içinde otomatik olarak banka hesabına aktarılmaktadır.</span>
        </div>
      </div>
    </div>


    <loader v-if="!pageLoaded"/>

    <div v-for="(payment, index) in payments" :key="index" class="payment-wrapper" style="margin-top: 30px">
      <div class="payment">
        <div style="display: flex; justify-content: flex-end">
          <div style="font-size: 14px; color: #5e6b79; font-weight: normal;">{{ Number(payment.created_at) | customDateFormat('dd MMM yyyy') }}</div>
        </div>
        <div class="description-box" style="margin: 20px auto 0">
          <div class="description">
            {{ payment.description }}
          </div>
        </div>
        <div class="payment-footer" style="justify-content: space-between">
          <div style="display: flex;">
            <div style="margin-left: 10px; font-weight: bold; font-size: 20px;" :style="`color: ${payment.total_color}`">
              {{payment.total}}
            </div>
          </div>
          <div class="analyse-label" :style="`border: solid 1px ${payment.status_color}; color: ${payment.status_color}`">
            {{ payment.status_text }}
          </div>
        </div>
      </div>

    </div>

    <div v-if="seemorediv && !showLoader" @click="retrieveHistory(true)"
         style="cursor: pointer; margin:40px 0;font-size: 14px; font-weight: 600;text-align: center; color: #2d3640;">
      Daha fazla gör +
    </div>
    <loader v-if="showLoader"/>
  </div>
</template>

<script>
  export default {
    name: "src-pages-body-workstation-finance-v2",
    data() {
      return {
        showInfo: false,
        pageLoaded: null,
        payments: [],
        seemorediv: false,
        offset: 0,
        limit: 10,
        showLoader: false
      }
    },

    methods: {
      hideInfo(){
        this.showInfo = false
        this.api.user.setClicked('finance_info');
      },
      retrieveHistory(seemore) {
        if (seemore) {
          this.showLoader = true
        }
        this.api.user.retrievePaymentHistory(this.limit, this.offset)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.payments = this.payments.concat(result.data.payment_history)
              this.seemorediv = result.data.seemorediv
              if(result.data.is_clicked){
                this.showInfo = true;
              }
              this.offset = this.offset + this.limit
            } else {
              this.$router.push('/')
            }
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
          .finally(() => {
            this.showLoader = false
            this.pageLoaded = true
          })
      }
    },
    computed: {
      infoTextTitle() {
        return !this.user.seller.isSeller ?
          "Yaptığım ödeme direkt freelancer'a mı geçiyor?" :
          "Siparişim onaylandı. Param nerede?"
      }
    },
    created() {
      this.EventBus.$on("retrieveWithdrawFunds", (payload) => {
        this.payments.unshift(payload);
      });
      this.retrieveHistory();
    }
  }
</script>

<style scoped lang="scss">
  .finance-info{
    width: 843px;
    height: 100px;
    border-radius: 10px;
    border: solid 1px rgba(242, 153, 51, 0.3);
    background-color: rgba(242, 153, 51, 0.2);
    margin-top: 30px;
    display: flex;
    align-items: center;
    position: relative;
    &.for-seller {
      border: solid 1px rgba(90, 38, 164, 0.3);
      background-color: rgba(90, 38, 164, 0.2);
    }
    .for-seller-icon /deep/{
      width: 36px;
      height: 36px;
      path{
        fill: #5a26a4;
      }
    }
    .for-buyer-icon /deep/{
      width: 36px;
      height: 36px;
      path{
        fill: #f29933;
      }
    }
    .delete-icon /deep/{
      width: 16px;
      height: 16px;
      path{
        fill: #2d3640;
      }
    }
    .title{
      font-size: 16px;
      font-weight: 500;
      color: #343d4a;
      padding-bottom: 4px;
    }
    .description{
      width: 668px;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.29;
      color: #4f596c;
    }
  }
  .payment-wrapper{
    width: 844px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;
    .payment{
      width: 783px;
      margin: 20px auto;
      .description-box{
        width: 783px;
        border-radius: 10px;
        background-color: #eff3f8;
        text-align: center;
        .description {
          width: 520px;
          margin: 0 auto;
          padding: 12px 0;
          font-size: 14px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #898ea9;
        }
      }
      .payment-footer{
        margin-top: 22px;
        display: flex;
        .live-analyse-button{
          text-align: center;
          cursor: pointer;
          width: 185px;
          height: 30px;
          line-height: 29px;
          border-radius: 4px;
          background-color: #5a26a4;
          color: #ffffff;
          font-size: 13px;
          display: flex;
          .analytics-icon /deep/{
            width: 16px;
            height: 16px;
            path {
              fill: #ffffff!important;
            }
          }
          &.active{
            background-color: #fd4056;
          }
        }
        .analyse-label{
          text-align: center;
          width: 125px;
          height: 28px;
          line-height: 27px;
          border-radius: 4px;
          border: solid 1px #5a26a4;
          font-size: 13px;
          background-color: #ffffff;
          color: #5a26a4;
          &.completed{
            border: solid 1px #6dce6a;
            color: #6dce6a;
          }
        }
      }
      .separator{
        margin-top: 20px;
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
      }
      .analytics{
        margin-top: 30px;
        display: flex;
        justify-content: center;
        .item{
          &:nth-child(n+2) {
            margin-left: 68px;
          }
          .title {
            display: flex;
            height: 22px;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.38;
            color: #8b95a1;
            &.active{
              color: #2d3640;
            }
            .info-icon /deep/{
              width: 14px;
              height: 14px;
              path {
                fill: #8b95a1!important;
              }
            }
            .info-icon-active /deep/{
              width: 14px;
              height: 14px;
              path {
                fill: #2d3640!important;
              }
            }
            .data-balloon{
              display: inline-flex;
              cursor: help;
              margin: auto;
            }
          }
          .value{
            height: 42px;
            font-size: 34px;
            font-weight: bold;
            letter-spacing: -0.35px;
            color: #5e6b79;
            text-align: center;
            &.active{
              color: #2d3640;
            }
            &.active-green{
              color: #00a575 !important;
            }
          }
        }
      }
    }
  }
  .content-header-text{
    font-size: 34px;
    font-weight: normal;
    color: #3c4654;
  }
  .content-header{
    margin-top: 31px;
    margin-bottom: 13px;
    width: 849px;
    align-items: center;
  }
</style>
